import { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext({
  currentTheme: 'light',
  changeCurrentTheme: () => {},
});

export default function ThemeProvider({children}) {  
  const [theme, setTheme] = useState( 'light');

  useEffect(() => {
    try {
      const persistedTheme = localStorage.getItem('theme');
      if (persistedTheme) {
        setTheme(persistedTheme);
      }
    } catch (err) {
      console.error('Error accessing localStorage:', err);
    }
  }, []);

  const changeCurrentTheme = (newTheme) => {
    try {
      setTheme(newTheme);
      localStorage.setItem('theme', newTheme);
    } catch (err) {
      console.error('Error setting theme in localStorage:', err);
    }
  };
  useEffect(() => {
    document.documentElement.classList.add('[&_*]:!transition-none');
    if (theme === 'light') {
      document.documentElement.classList.remove('dark');
      document.documentElement.style.colorScheme = 'light';
    } else {
      document.documentElement.classList.add('dark');
      document.documentElement.style.colorScheme = 'dark';
    }

    const transitionTimeout = setTimeout(() => {
      document.documentElement.classList.remove('[&_*]:!transition-none');
    }, 1);
    
    return () => clearTimeout(transitionTimeout);
  }, [theme]);

  return <ThemeContext.Provider value={{ currentTheme: theme, changeCurrentTheme }}>{children}</ThemeContext.Provider>;
}

export const useThemeProvider = () => useContext(ThemeContext);